@import './shared/styles/_vuetify-overrides.scss';

















































.cassie-header-text-color {
	color: var(--global-header-text-color);
}
